require('normalize.css/normalize.css');
require('../css/main.css');
require('./page.css');
require('../assets/menu_home.png');
require('../assets/home.png');
require('../assets/hand.png');
require('../assets/close.png');
require('../assets/conclusion.png');
require('../assets/logo.png');
require('../assets/logo_daat.png');
require('../assets/header.png');
require('../assets/question_1_1.png');
require('../assets/question_2_1.png');
require('../assets/menu_1_off.png');
require('../assets/menu_1_on.png');
require('../assets/menu_2_off.png');
require('../assets/menu_2_on.png');
require('../assets/menu_3_off.png');
require('../assets/menu_3_on.png');
require('../assets/menu_ref.png');
require('../assets/bubble1.png');
require('../assets/bubble2.png');
require('../assets/bubble.png');
require('../assets/cigs.png');
require('../assets/background.png');
require('../assets/tableau.png');
require('../assets/bubble_right.png');
require('../assets/bubble_left.png');
require('../assets/bubble31.png');
require('../assets/bubble21.png');
require('../assets/bubble221.png');
require('../assets/bubble22.png');
require('../assets/bubbles22.png');
require('../assets/icone_ref.png');
require('../assets/favicon.ico');
require('../assets/clic.mp3');
require('../assets/gagne.mp3');
require('../assets/perdu.mp3');
import {TweenMax, Power2, TimelineLite} from "gsap/TweenMax";
var $ = require('jquery');
var assets = {
  template_title_1: '<div class="cervoregular_italic size34" id="title">D\’après les dernières recommandations dans quels cas faudrait-il rechercher un DAAT ? <sup>(4)</sup></div><br/><span class="consigne cervolight_italic size20">Cochez la ou les bonne(s) réponse(s)</span>',
  template_title_2: '<div class="cervoregular_italic size34" id="title">Et chez les patients fumeurs faut-il dépister un DAAT ? </div><br/><span class="consigne cervolight_italic size20">Cochez la ou les bonne(s) réponse(s)</span>',
  template_title_3: '<div class="cervoregular_italic size34" id="title">Quelle(s) question(s) jugez-vous contributive(s) face à ce patient en consultation ? </div><br/><span class="consigne cervolight_italic size20">Cochez la ou les bonne(s) réponse(s)</span>',
  template_title_4: '<div class="cervoregular_italic size34" id="title">Voici les réponses du patient : que faites-vous ? </div><br/><span class="consigne cervolight_italic size20">Cochez le ou les protocoles(s) de soins adaptés(s) à la situation</span>',
  template_title_5: '<div class="cervoregular_italic size34" id="title">Quels paramètres sont à considérer pour ce patient ? </div><br/><span class="consigne cervolight_italic size20">Cochez la ou les question(s) pertinente(s)</span>',
  template_title_6: '<div class="cervoregular_italic size34" id="title">Devant ce tableau clinique, que faites-vous ?</div><br/><span class="consigne cervolight_italic size20">Cochez la ou les question(s) pertinente(s)</span>',
  template_title_7: '<div class="cervoregular_italic size34" id="title">CONCLUSION</div>'
}
var config = {
  URL_IMG: 'assets/',
  SERVICE : 'https://daat-staging.jake-digital.com/backend/send.php',
  TIMER : 1
}

/**
 * * *******************
 * * AUDIO
 * * *******************
 */
function PlayAudio() {
  var _instance = {}
  var _audio = null;
  _instance.play = function (id) {
    var x = document.getElementById(id);
    x.play();
  };
  _instance.pause = function () {
    var x = document.getElementById(id);
    x.pause();
  };
  _instance.killAudio = function () {}
  return _instance;
}
/**
 * * *******************
 * * ACTIVITY CONTROL
 * * *******************
 */
function CkeckActivity() {
  var _instance = {}
  var idleInterval;
  var idleTime = 0;
  _instance.initTimer = function () {
    idleInterval = setInterval(timerIncrement, 1000);
  }
  _instance.clearTimer = function () {
    clearInterval(idleInterval)
  }
  _instance.clearIdleTime = function () {
    idleTime = 0;
  }

  function timerIncrement() {
    idleTime = idleTime + 1;
  
    if (idleTime > 59) { // 1 minute
      idleTime = 0;
     
      if (!Main.getAppView().getViewActive()) return
       var _viewActive =Main.getAppView().getViewActive(); 
       var _id = _viewActive.id.split('screen_')[1]; 
       var _table = "";
       if(_id!=7) {
       var _max = 6; 
       var _isAnswered = document.body.classList.contains('validate');
       var _ref = 0; 
       if(_isAnswered) {_ref= (Number(_id)+1)}else{ _ref = Number(_id)};
       for(var k = _ref; k <=_max;k++) {
        _table = document.getElementById('screen_'+k).dataset.table;
        Main.getAppservice().getDatasToSend(k,1,_table);
       }
       Main.getAppservice().sendData({"user":"X"},null,"fin_jeu");
       }
      
      Main.getAppController().gotoHome();
    }
  }
  return _instance
}

function checkAnswer() {
  var win = 1
  var _viewActive = Main.getAppView().getViewActive();
  _viewActive.querySelectorAll("form").forEach(function (id) {
    id.querySelectorAll("input").forEach(function (id) {
      if (id.checked) {
        if (id.classList.contains('red_gradient')) {
          win = 0
          return
        }
      }
      if (id.classList.contains('green_gradient')) {
        if (!id.checked) {
          win = 0
          return
        }
      }
    })
  });
  win ? Main.getAppAudio().play('gagne') : Main.getAppAudio().play('perdu')
}

function checkForm() {
  var _viewActive = Main.getAppView().getViewActive();
  var n = 0;
  _viewActive.querySelectorAll("form").forEach(function (id) {
    id.querySelectorAll("input").forEach(function (id) {
      if (id.checked) n++
    })
  });
  return n === _viewActive.querySelectorAll("form").length ? true : false
}

function addEventForm() {
  var _viewActive = Main.getAppView().getViewActive()
  if (_viewActive) {
    for (var k = 0; k < _viewActive.querySelectorAll("form").length; k++) {

      for (var u = 0; u < _viewActive.querySelectorAll("form")[k].querySelectorAll("input").length; u++) {
        _viewActive.querySelectorAll("form")[k].querySelectorAll("input")[u].addEventListener('change', function () {

          Main.getAppAudio().play('clic');
         // Main.getAppActivity().clearIdleTime();
          checkForm() ? document.getElementById('button_valid').classList.remove('desactive') : ''
        })
      }
    }
  }
}
/**
 * * *******************
 * * ACTIVITY SERVICE
 * * *******************
 */
function Service(){

  var _instance = {};
  var _progression = 2;
  var _ref = 1
  var datasTosend = {}
  var _table="";
   var sendData = function (datas,id,table) {

   // console.log("sendData =>" ,datas);
    
    var _viewActive = Main.getAppView().getViewActive();
    if(table!=""){
      _table = table;
    } else {
      _table = _viewActive.dataset.table;
      
    }
   
    var request = $.ajax({
        url: config.SERVICE+"?table="+_table,
        type: "post",
        data: datas
    });
     request.done(function (response, textStatus, jqXHR){
        // Log a message to the console
       // console.log(response);
       
    });
     // Callback handler that will be called on failure
     request.fail(function (jqXHR, textStatus, errorThrown){
        // Log the error to the console
        console.error("The following error occurred: "+ textStatus, errorThrown);
    });
    }

    _instance.getDatasToSend = function(id,abandonment,table) {
      datasTosend = {};
      _ref = 1;
     // console.log("*******getDatasToSend********")
     // console.log("TABLE => " , table )
     // console.log(id , " / " , abandonment )
      if(Number(id)===1) {
        for(var k =1;k<10;k++){
          var _term = $('.form-'+k).find("input[name='form-"+k+"-section-1']");
          $.each(_term, function (index, value) {
            if(abandonment) {
              datasTosend["reponse_"+_ref] = "X";

            } else {
               datasTosend["reponse_"+_ref] = value.checked ? "OUI" : "NON";
            }
            _ref++;  
            });
        }
      } else {
         var _term = $('.form-1_section_'+id).find("input[name='form-1_section_"+id+"']");
       //  console.log("_term", _term )
          $.each(_term, function (index, value) {
            if(abandonment) {
              datasTosend["reponse_"+_ref] = "X";
            } else {
              datasTosend["reponse_"+_ref] = value.checked ? "OUI" : "NON";
            }
            _ref++;
              
            });
      }
     // console.log('datasTosend =>', datasTosend)
     // console.log("*******getDatasToSend********")
    sendData(datasTosend,id,table)
    
    }

      _instance.sendData = function(datasTosend,id,table){
        sendData(datasTosend,id,table)
      }

      return _instance;
}
/**
 * * *******************
 * * CONTROLLER
 * * *******************
 */
function Controller() {
  var _instance = {}
  var _activeScreen = 0;
  var popup_active = document.getElementById("references");
  var overlay = document.getElementById("overlay");
  
  var nextScreen = function (e, song, isTransition) {
    TweenMax.killAll()
    //Main.getAppActivity().clearIdleTime()
    isTransition ? playTransition() : null;
    document.body.classList.remove('validate')
    document.getElementById('button_valid').classList.add('desactive')
    document.getElementById('button_valid').style.display = 'block';
    Main.getAppAudio().play(song);
  }
  _instance.gotoHome = function () {
    //Main.getAppActivity().clearTimer();

    // Register data :

    if (!Main.getAppView().getViewActive()) return
       var _viewActive =Main.getAppView().getViewActive(); 
       var _id = _viewActive.id.split('screen_')[1]; 
       var _table = "";
       if(_id!=7) {
       var _max = 6; 
       var _isAnswered = document.body.classList.contains('validate');
       var _ref = 0; 
       if(_isAnswered) {_ref= (Number(_id)+1)}else{ _ref = Number(_id)};
       for(var k = _ref; k <=_max;k++) {
        _table = document.getElementById('screen_'+k).dataset.table;
        Main.getAppservice().getDatasToSend(k,1,_table);
       }
       Main.getAppservice().sendData({"user":"X"},null,"fin_jeu");
       }



    document.querySelector('[data-item="menu-3_on"]').classList.remove('isVisible');
    document.querySelector('[data-item="menu-3_on"]').classList.remove('isActive');
    document.querySelector('[data-item="menu-3_off"]').classList.add('isVisible');
    document.querySelector('[data-item="menu-2_on"]').classList.remove('isVisible');
    document.querySelector('[data-item="menu-2_on"]').classList.remove('isActive');
    document.querySelector('[data-item="menu-2_off"]').classList.add('isVisible');
    document.querySelector('[data-item="menu-1_off"]').classList.remove('isVisible');
    document.querySelector('[data-item="menu-1_on"]').classList.add('isVisible');
    document.querySelector('[data-item="menu-1_on"]').classList.add('isActive');
    document.querySelectorAll("input").forEach(function (id) {
      id.checked = false
    })

    for (var i = 1; i < document.getElementsByClassName('section').length; i++) {
      document.getElementsByClassName('section')[i].style.zIndex = i;
      document.getElementsByClassName('section')[i].style.display = 'none';
      document.getElementsByClassName('section')[i].classList.remove('correction');
    }
    document.getElementById('screen_0').style.display = 'block';
    document.getElementById('background').style.display = 'block';
    document.getElementById('background').style.opacity = 1;
    document.body.classList.remove('validate');
    document.body.classList.remove('end');
    _activeScreen = 0;
    Main.getAppView().setViewActive(document.getElementById('screen_0'));
    document.getElementById('button_valid').style.display = "block";
    Main.getAppIntro().play();
  }
  _instance.openPopup = function () {
    Main.getAppAudio().play('clic');
    popup_active.style.visibility = 'visible';
    overlay.style.visibility = 'visible';
    TweenMax.to(popup_active, 0.5, {
      y: 0,
      opacity: 1
    });

  }
  _instance.closePopup = function () {
    Main.getAppAudio().play('clic');
    overlay.style.visibility = 'hidden';
    //Main.getAppActivity().clearIdleTime();
    TweenMax.to(popup_active, 0.5, {
      opacity: 0,
      onComplete: function () {
        popup_active.style.visibility = 'hidden';
      }
    });

  }

  _instance.addEvents = function () {
    addEventForm();
    /** CLOSE */
    document.getElementById('close').addEventListener(AppEvents().globalEvents.mousedown, function (e) {
      Main.getAppController().closePopup();
    })
    /** VALIDER **/
    document.getElementById('button_valid').addEventListener(AppEvents().globalEvents.mousedown, function (e) {

      checkForm() ? e.currentTarget.classList.remove('desactive') : ''
      if (e.currentTarget.classList.contains("desactive")) {
        return;
      }
      e.currentTarget.style.display = "none";
      for (var u = 0; u < document.getElementsByTagName('input').length; u++) {
        document.getElementsByTagName('input')[u].setAttribute('disabled', true);
      }
     
      document.body.classList.add('validate');
      document.getElementById('button_next').style.display = "block";
      var _viewActive = Main.getAppView().getViewActive();
      if (_viewActive) {
        _viewActive.classList.add('correction');
      }
      document.getElementsByClassName('consigne')[0].style.display = "none";
      checkAnswer();
      Main.getAppservice().getDatasToSend(Main.getAppView().getViewActive().id.split('screen_')[1],0,"");
      

    })
    /** NEXT **/
    document.getElementById('button_next').addEventListener(AppEvents().globalEvents.mousedown, function (e) {
      e.currentTarget.style.display = "none";
      for (var u = 0; u < document.getElementsByTagName('input').length; u++) {
        document.getElementsByTagName('input')[u].removeAttribute('disabled');
      }
      nextScreen(e, 'clic', 1);

    })
    /** RIGHT MENU */
    var _menuItem = document.getElementsByClassName('menu_right_item')
    for (var i = 0; i < _menuItem.length; i++) {
      _menuItem[i].addEventListener(AppEvents().globalEvents.mousedown, function (e) {
        if (e.currentTarget.classList.contains('home')) {
          Main.getAppController().gotoHome();
        } else {
          Main.getAppController().openPopup();
        }
      })
    }

    document.getElementById('back_home').addEventListener(AppEvents().globalEvents.mousedown, function (e) {
      Main.getAppservice().sendData({"user":1},null,"");
      Main.getAppController().gotoHome();
    })


    var _screen_home = document.getElementById("screen_0");
    _screen_home.addEventListener(AppEvents().globalEvents.mousedown, function (e) {
      Main.getAppView().setViewActive(document.getElementById('screen_0'));
      Main.getAppservice().sendData({"user":1},null,"");
      
      TweenMax.killAll();
      nextScreen(e, 'clic', 1);
     // if(config.TIMER) Main.getAppActivity().initTimer();
    
    })
    return;

  }



  var manageMenu = function (from, to) {
    document.querySelector('[data-item="menu-' + from + '_on"]').classList.remove('isVisible')
    document.querySelector('[data-item="menu-' + from + '_on"]').classList.remove('isActive')
    document.querySelector('[data-item="menu-' + from + '_off"]').classList.add('isVisible')
    document.querySelector('[data-item="menu-' + to + '_off"]').classList.remove('isVisible')
    document.querySelector('[data-item="menu-' + to + '_on"]').classList.add('isVisible')
    document.querySelector('[data-item="menu-' + to + '_on"]').classList.add('isActive')
  }

  var playTransition = function () {
    _activeScreen = getViewActive();
    var _nextScreen = Number(_activeScreen + 1);
    var _activedElt = document.getElementById('screen_' + _activeScreen);
    var _activetoElt = document.getElementById('screen_' + _nextScreen);
    if (_nextScreen === 3) {
      manageMenu(1, 2)
    }
    if (_nextScreen === 5) {
      manageMenu(2, 3)
    }

    if (_nextScreen === 7) {
      document.body.classList.add('end');
      TweenMax.fromTo(document.getElementById('conclusion_hand'), 0.3, {
        scale: 1
      }, {
        delay: 1.5,
        scale: 0.9,
        repeat: -1,
        repeatDelay: 0,
        yoyo: true,
        transformOrigin: 'center center'
      });

    }
    document.getElementById('wrapper-title').innerHTML = assets['template_title_' + _nextScreen]
    _activetoElt.style.zIndex = _activedElt.style.zIndex;
    _activetoElt.style.display = 'block';
    var _elts = []
    if (!_activeScreen) {
      _elts = [_activedElt, document.getElementById('background')]
    } else {
      _elts = [_activedElt]
    }
    TweenMax.to(_elts, 0.8, {
      opacity: 0,
      onCompleteScope: this,
      onComplete: function () {
        document.getElementById('background').style.display = 'none'
        this.target[0].style.display = 'none'
        _activeScreen = _activeScreen + 1
        _activedElt = _activetoElt
        Main.getAppView().setViewActive(document.getElementById('screen_' + _activeScreen))
        addEventForm();
      }
    });
    TweenMax.to(_activetoElt, 0.8, {
      opacity: 1
    });

  }

  var getViewActive = function () {
    return _activeScreen
  }

  var setViewActive = function (active) {
    _activeScreen = active
  }
  _instance.setViewActive = function (active) {
    setViewActive(active)
  }


  return _instance


}
/**
 * * *******************
 * * HOME
 * * *******************
 */

function PlayIntro() {
  TweenMax.killAll()
  var _instance = {};

  _instance.play = function () {
    var _el = document.getElementById('screen_0');
    var _hand = document.getElementById('hand');
    document.getElementById("footer").style.display = 'block'
    _hand.style.opacity = 0
    TweenMax.set(_hand, {
      opacity: 0,
      y: 200

    });

    TweenMax.to(_el, 0.8, {
      opacity: 1
    });
    TweenMax.to(_hand, 0.8, {
      opacity: 1,
      y: 0,
      delay: 0.5
    });

    TweenMax.fromTo(_hand, 0.3, {
      scale: 1
    }, {
      delay: 1.5,
      scale: 0.9,
      repeat: -1,
      repeatDelay: 0,
      yoyo: true,
      transformOrigin: 'center center'
    });
  }

  return _instance

}
/**
 * * *******************
 * * EVENTS
 * * *******************
 */

function AppEvents() {
  // add mstouch
  var _GlobalEvents = {
    'mousedown': isTablet() ? 'touchstart' : 'mousedown',
    'mousemove': isTablet() ? 'touchmove' : 'mousemove',
    'mouseleave': isTablet() ? 'touchend' : 'mouseleave',
    'mouseup': isTablet() ? 'touchend' : 'mouseup',
    'click': isTablet() ? 'tap' : 'click'
  };

  return {
    globalEvents: _GlobalEvents
  }
}
/**
 * * *******************
 * * MANAGE VIEWS
 * * *******************
 */
function View() {
  var _instance = {};
  var _viewActive = null;
  _instance.createView = function () {
    document.getElementById('container-lds-ripple').style.display = 'none';
    for (var i = 1; i < document.getElementsByClassName('section').length; i++) {
      document.getElementsByClassName('section')[i].style.zIndex = 10 - i;
    }
  };
  _instance.getViewActive = function () {
    return _viewActive
  }
  _instance.setViewActive = function (view) {
    _viewActive = view
  }
  return _instance

}
/**
 * * *******************
 * * INIT
 * * *******************
 */
(function () {

  var _instance = {};
  var _appContainer = null;
  var _appActivity = null;
  var _appView = null;
  var _appIntro = null;
  var _appController = null;
  var _factor = 1;
  var _resizeTimeout;
  var _w_app = 1920;
  var _h_app = 1080;
  var _appAudio;
  var _appService = null;

  function preventDefault(e) {
    e.preventDefault();
  }

  _instance.init = function () {
    resizeThrottler();
    window.addEventListener("resize", resizeThrottler, false);
    _appContainer = document.getElementById('quiz-container');
    _appContainer.style.display='block';
    _appService = new Service()
    _appView = new View();
    _appView.createView();
    _appIntro = new PlayIntro();
    _appIntro.play();
    //_appActivity = new CkeckActivity();
    _appController = new Controller();
    _appAudio = new PlayAudio();
    _appController.addEvents();
   
  }

  function resizeThrottler() {
    if (!_resizeTimeout) {
      _resizeTimeout = setTimeout(function () {
        _resizeTimeout = null;
        resize();
      }, 66);
    }
  }
  _instance.getAppservice = function () {
    return _appService
  }
  _instance.getAppView = function () {
    return _appView
  }
  _instance.getAppIntro = function () {
    return _appIntro
  }
  _instance.getAppAudio = function () {
    return _appAudio
  }
  _instance.getAppContainer = function () {
    return _appContainer
  }
  _instance.getAppActivity = function () {
    return _appActivity
  }
  _instance.getAppController = function () {
    return _appController
  }
  /**
 * * *******************
 * * UTILS
 * * *******************
 */
  function resize() {
    var _ratio_W = window.innerWidth / _w_app;
    var _ratio_H = window.innerHeight / _h_app;
    if (_ratio_H < _ratio_W) {
      setScale(_appContainer, _ratio_H)

    } else {
      setScale(_appContainer, _ratio_W)

    }
    setTransformOrigin(_appContainer, 'top left')
  }
  function setScale(elt, val) {
    elt.style['-webkit-transform'] = 'scale(' + val + ',' + val + ')';
    elt.style['-moz-transform'] = 'scale(' + val + ',' + val + ')';
    elt.style['-o-transform'] = 'scale(' + val + ',' + val + ')';
    elt.style['-ms-transform'] = 'scale(' + val + ',' + val + ')';
    elt.style['transform'] = 'scale(' + val + ',' + val + ')';
  }
  function setTransformOrigin(elt, val) {
    elt.style['-webkit-transform-origin'] = val;
    elt.style['-moz-transform-origin'] = val;
    elt.style['-o-transform-origin'] = val;
    elt.style['-ms-transform-origin'] = val;
    elt.style['transform-origin'] = val;
  }
  window.Main = _instance
})()

function isTablet() {
  if (navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  } else {
    return false;
  }
}

document.addEventListener("DOMContentLoaded", () => {
    Main.init();
  
  
});
